import React from 'react';
import PropTypes from 'prop-types';

const ModalDialogSupport = ({ classWrap = '' }) => {
  return (
    <p className={`nsc-r modal-support ${classWrap}`}>
      <span className="sc-r">For questions, information, and to share stories of kindness, please email </span>
      <a href="mailto:hello@santakindness.com">
        <b>hello@santakindness.com</b>
      </a>{' '}
      <span className="sc-r">or call </span>
      <a href="tel:8883363226">
        <b>888.336.3226</b>
      </a>
      .
      <br />
      <span className="sc-r">Mon - Fri, 8am - 12pm & 12:30pm - 5pm CT</span>
    </p>
  );
};

export default ModalDialogSupport;
ModalDialogSupport.propTypes = {
  classWrap: PropTypes.string,
};
