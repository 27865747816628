import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/user-verify.scss';
import { Context } from '../index';
import LayoutForm from '../components/user/LayoutForm';
import { INDEX_ROUTE } from '../utils/consts';
import { useNavigate } from 'react-router-dom';

const UserVerifyMessage = () => {
  const { userStore } = useContext(Context);
  const [message] = useState({});
  const history = useNavigate();
  const msgRef = useRef(null);

  useEffect(() => {
    if (!localStorage.getItem('tokenUser')) {
      history(INDEX_ROUTE);
    }
  }, [userStore.isAuth, userStore.isLoading, userStore.user]);

  return (
    <LayoutForm classSelector={'user-verify'} title={'Verify Email Address'}>
      <span>
        We’ve sent a verification email to <b>{userStore.user.email}</b>.
      </span>
      <br />
      <div className="mt-2 mb-2">
        <span>Click on the link in the email to continue signing up.</span>
      </div>
      <span>
        Register your device on your phone or tablet. For the smoothest experience, access your confirmation email via{' '}
        <b>the same phone or tablet</b> to complete your registration
      </span>
      <div className={'block-message'}>
        <div ref={msgRef}></div>
        <div className="invalid-feedback">{message.error}</div>
        {message.success && <div className="valid-feedback">{message.success}</div>}
      </div>
      <div className="bottom-info">
        <div className="sub-text">
          <span className="sc-r">Still not received? Please, be sure that you entered a correct</span>{' '}
          <b className="sc-r">email address</b> <span className="sc-r">or try later</span>
        </div>
      </div>
    </LayoutForm>
  );
};

export default observer(UserVerifyMessage);
